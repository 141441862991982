import React from 'react'
import Insights from '../components/Insights'

function InsightsPage() {
  return (
    <main >
    <Insights/>
    </main>
    
  )
};

export default InsightsPage;